import { AnimatedSpinner, Button, Dialog, IconSearch } from '@madpaws/design-system';
import { useFormikContext } from 'formik';
import { useState } from 'react';

import { SearchFiltersFormMobile } from '~/components/SearchFiltersForm/SearchFiltersFormMobile/SearchFiltersFormMobile';
import { DOM_CONTAINER_ID } from '~/components/constants';

import styles from './MobileSearchWidget.module.css';

import type { OnBeforeModalOpen } from './types';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type Props = {
  onBeforeModalOpen?: OnBeforeModalOpen;
};

export const MobileSearchWidget = ({ onBeforeModalOpen }: Props): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { handleSubmit: handleFormSubmit, isSubmitting } = useFormikContext<SearchFilters>();
  const label = 'Book a sitter near you';

  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  };

  const handleDialogOpen = (): void => {
    if (onBeforeModalOpen) {
      onBeforeModalOpen({ label });
    }

    setIsDialogOpen(true);
  };

  return (
    <>
      <div className={styles.root}>
        <Button
          iconLeading={<IconSearch />}
          label={label}
          onClick={handleDialogOpen}
          variant="tertiary"
        />
      </div>
      <Dialog
        cta={{
          iconLeading: isSubmitting ? <AnimatedSpinner /> : <IconSearch />,
          isDisabled: isSubmitting,
          size: 'large1x',
          label: 'Find a Sitter in my area',
          // Submit the form from here
          onClick: () => handleFormSubmit(),
        }}
        domContainerId={DOM_CONTAINER_ID}
        heading="Find a Sitter in your area"
        isOpen={isDialogOpen}
        onRequestClose={handleDialogClose}
      >
        <SearchFiltersFormMobile />
      </Dialog>
    </>
  );
};
